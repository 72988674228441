@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin hide-text {
    font: 0/0 a;
    box-shadow: none;
    color: transparent;
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin clearfix {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}
@mixin query($width) {
    @media (max-width: #{$width}) {
        @content;
    }
}

@mixin user-select($value) {
  -webkit-touch-callout: $value;
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

@mixin gradient($start, $end, $direction:"top") {
    $dir: top;
    $revDir: bottom;

    @if $direction == "top" {
        $dir: top;
        $revDir: bottom;
    }
    @if $direction == "left" {
        $dir: left;
        $revDir: right;
    }
    @if $direction == "bottom" {
        $dir: bottom;
        $revDir: top;
    }
    @if $direction == "right" {
        $dir: right;
        $revDir: left;
    }

    background: -webkit-linear-gradient($dir, $start, $end); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($revDir, $start, $end); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($revDir, $start, $end); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to $revDir, $start, $end);
}

@mixin transform($transforms) {
       -moz-transform: $transforms;
         -o-transform: $transforms;
        -ms-transform: $transforms;
    -webkit-transform: $transforms;
          transform: $transforms;
}


@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 

@mixin scale($scale) {
     @include transform(scale($scale));
} 

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
         -o-transform-origin: $origin;
        -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
          transform-origin: $origin;
}

@mixin perspective($val) {
  -webkit-perspective: $val; 
  -moz-perspective: $val; 
  -ms-perspective: $val; 
  perspective: $val;
}

@mixin keyframes($name) {
  @-o-keyframes #{$name} { @content };
  @-moz-keyframes #{$name} { @content };
  @-webkit-keyframes #{$name} { @content }; 
  @-keyframes #{$name} { @content };
}
@mixin animation-name($name...) {
  -o-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-delay($duration...) {
  -o-animation-delay: $duration;
  -moz-animation-delay: $duration;
  -webkit-animation-delay: $duration;
  animation-delay: $duration;
}
@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-timing-function($timing...) {
  -o-animation-timing-function: $timing;
  -moz-animation-timing-function: $timing;
  -webkit-animation-timing-function: $timing;
  animation-timing-function: $timing;
}
@mixin animation-iteration-count($count...) {
  -o-animation-iteration-count: $count;
  -moz-animation-iteration-count: $count;
  -webkit-animation-iteration-count: $count;
  animation-iteration-count: $count;
}
@mixin animation-direction($direction...) {
  -o-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill...) {
  -o-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -webkit-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}
@mixin animation-play-state($state...) {
  -o-animation-play-state: $state;
  -moz-animation-play-state: $state;
  -webkit-animation-play-state: $state;
  animation-play-state: $state;
}
@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin svg-style {
    svg {
        path, polyline, line, rect, polygon, circle {
          @content;
        }
    }
}