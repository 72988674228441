html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1em;
  font: inherit;
  vertical-align: baseline;
}
p {
  margin: 1.25em 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, block, iframe {
  display: block;
}
block {
  position: relative;
}

body {
  line-height: 1;
}

ol {
  counter-reset: ol-counter;
  list-style: none;

  li {
    margin: 1em 0;
    padding-left: 2em;
    position: relative;
    &:before {
      display: flex;
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: counter(ol-counter);
      counter-increment: ol-counter;
      border-radius: 1.5em;
      background-color: #333;
      color: #fff;
      align-items: center;
      justify-content: center;
      align-content: center;
      text-align: center;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
ul {
  list-style: none;
  &.no-bullet {
    list-style: none;
  }
}

blockquote, q {
  quotes: none;

  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}