autocomplete {
    z-index: 2;

    .autocomplete {
        position: relative;
        display: inline-block;

        &-close {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            z-index: 2;
            color: color(gray);
        }

        &-input {
            outline: none;
        }

        &-list {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: color(white);
            box-sizing: border-box;
            border-radius: $inputRad;
            border: 1px solid color(gray-light);
            z-index: 2;
            max-height: 16em;
            overflow: auto;

            li {
                line-height: 1;
                padding: .5em;
                transition: all .25s;
                cursor: pointer;

                div {
                    font-size: .85em;
                }
                &.selected {
                    background-color: #eee;
                }
                &:hover {
                    background-color: #fef2d0;
                }
            }
        }

        &.top {
            .autocomplete-list {
                top: auto;
                bottom: 100%;
            }
        }
    }
}