.view.games {
	display: flex;
	flex-direction: column;
	height: 100vh;

	.games {
		padding: 0;
		
		.game {
			padding: .5em 1em;

			&.odd {
				background-color: color(gray-lightest);
			}
		}
	}
}