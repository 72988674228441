[data-loader]
{
    margin: 8px;
}

$color: color(brand-primary);

/*
data-loader="arrow-circle"
data-loader="ball-circle"
data-loader="ball-fade"
data-loader="ball-pulse"
data-loader="ball-roll"
data-loader="ball-rotate"
data-loader="ball-scale"
data-loader="circle"
data-loader="circles"
data-loader="circle-clock"
data-loader="circle-scale"
data-loader="circle-side"
data-loader="heart"
data-loader="jumping"
data-loader="rectangle"
data-loader="satellite"
data-loader="spinner"
data-loader="spinner-circle"
data-loader="timer"
*/

[data-loader="circles"] {
    $size: 10vh;
    display: block;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: .5vh solid transparent;
    border-top-color: #3498db;
    animation: circles 1.5s linear infinite;

    &:before {
        content: "";
        position: absolute;
        top: 1vh;
        left: 1vh;
        right: 1vh;
        bottom: 1vh;
        border-radius: 50%;
        border: .5vh solid transparent;
        border-top-color: #e74c3c;
        animation: circles 3s linear infinite;
    }
    &:after{
        content: "";
        position: absolute;
        top: 2.5vh;
        left: 2.5vh;
        right: 2.5vh;
        bottom: 2.5vh;
        border-radius: 50%;
        border: .5vh solid transparent;
        border-top-color: #f9c922;
        animation: circles 2s linear infinite;
    }
}

@include keyframes(circles){
    0%   {
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(rotate(360deg));
    }
}
[data-loader='circle']
{
    width: 25px;
    height: 25px;

    animation: circle infinite .75s linear;

    border: 2px solid $color;
    border-top-color: transparent;
    border-radius: 100%;
}
[data-loader='circle-side']
{
    position: relative;

    width: 25px;
    height: 25px;

    animation: circle infinite .75s linear;

    border: 2px solid $color;
    border-top-color: rgba(0, 0, 0, .2);
    border-right-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .2);
    border-radius: 100%;
}
@include keyframes(circle){
    0% {
        @include transform(rotate(0));
    }
    100% {
        @include transform(rotate(360deg));
    }
}

[data-loader='arrow-circle']
{
    position: relative;

    width: 25px;
    height: 25px;

    animation: arrow-circle infinite .75s linear;

    border: 2px solid $color;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 100%;
}
[data-loader='arrow-circle']:before,
[data-loader='arrow-circle']:after
{
    position: absolute;
    top: 19px;
    left: -3px;

    content: '';
    @include transform(rotate(-30deg));

    border-top: 5px solid $color;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
[data-loader='arrow-circle']:after
{
    top: 0;
    left: 17px;

    @include transform(rotate(150deg));
}

@include keyframes(arrow-circle)
{
    0%
    {
        @include transform(rotate(360deg));
    }
    100%
    {
        @include transform(rotate(0));
    }
}

[data-loader='ball-scale']
{
    width: 50px;
    height: 50px;

    animation: ball-scale infinite linear .75s;

    border-radius: 100%;
    background-color: $color;
}
@include keyframes(ball-scale)
{
    0%
    {
        -webkit-transform: scale(.1);
            -ms-transform: scale(.1);
             -o-transform: scale(.1);
                transform: scale(.1);

        opacity: 1;
    }
    100%
    {
        -webkit-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);

        opacity: 0;
    }
}

[data-loader='ball-rotate']
{
    position: relative;

    width: 15px;
    height: 15px;

    animation: ball-rotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;

    border-radius: 100%;
    background-color: $color;

    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
[data-loader='ball-rotate']:before,
[data-loader='ball-rotate']:after
{
    position: absolute;

    width: 15px;
    height: 15px;
    margin: 2px;

    content: '';

    opacity: .8;
    border-radius: 100%;
    background-color: $color;
}
[data-loader='ball-rotate']:before
{
    top: 0;
    left: -28px;
}
[data-loader='ball-rotate']:after
{
    top: 0;
    left: 25px;
}
@include keyframes(ball-rotate)
{
    0%
    {
        @include transform(rotate(0deg) scale(1));
    }
    50%
    {
        @include transform(rotate(180deg) scale(.6));
    }
    100%
    {
        @include transform(rotate(360deg) scale(1));
    }
}

[data-loader='ball-pulse']
{
    position: relative;

    width: 1px;
    height: 1px;
}
[data-loader='ball-pulse']:before,
[data-loader='ball-pulse']:after
{
    position: absolute;

    display: inline-block;

    width: 15px;
    height: 15px;

    content: '';

    border-radius: 100%;
    background-color: $color;
}
[data-loader='ball-pulse']:before
{
    left: -15px;

    animation: ball-pulse infinite .75s -.4s cubic-bezier(.2, .68, .18, 1.08);
}
[data-loader='ball-pulse']:after
{
    right: -15px;

    animation: ball-pulse infinite .75s cubic-bezier(.2, .68, .18, 1.08);
}
@include keyframes(ball-pulse)
{
    0%
    {
        @include transform(scale(1));

        opacity: 1;
    }
    50%
    {
        @include transform(scale(.1));

        opacity: .6;
    }
    100%
    {
        @include transform(scale(1));

        opacity: 1;
    }
}

[data-loader='ball-circle']
{
    position: relative;

    width: 40px;
    height: 40px;
}
[data-loader='ball-circle']:before,
[data-loader='ball-circle']:after
{
    position: absolute;

    width: 10px;
    height: 10px;

    content: '';

    border-radius: 100%;
    background-color: $color;
}
[data-loader='ball-circle']:before
{
    @include transform(translate(0, 0));
    animation: ball-circle-before infinite 1.5s linear;
}
[data-loader='ball-circle']:after
{
    @include transform(translate(30px, 30px));
    animation: ball-circle-after infinite 1.5s linear;
}

@include keyframes(ball-circle-after)
{
    0%
    {
        @include transform(translate(30px, 30px));
    }
    25%
    {
        @include transform(translate(0, 30px));
    }
    50%
    {
        @include transform(translate(0, 0));
    }
    75%
    {
        @include transform(translate(30px, 0));
    }
    100%
    {
        @include transform(translate(30px, 30px));
    }
}

@include keyframes (ball-circle-before)
{
    0%
    {
        @include transform(translate(0, 0));
    }
    25%
    {
        @include transform(translate(30px, 0));
    }
    50%
    {
        @include transform(translate(30px, 30px));
    }
    75%
    {
        @include transform(translate(0, 30px));
    }
    100%
    {
        @include transform(translate(0, 0));
    }
}

[data-loader='rectangle']
{
    position: relative;

    width: .25em;
    height: .25em;

    animation: rectangle infinite 1s ease-in-out -.2s;

    background-color: $color;
}
[data-loader='rectangle']:before,
[data-loader='rectangle']:after
{
    position: absolute;

    width: .25em;
    height: .25em;

    content: '';

    background-color: $color;
}
[data-loader='rectangle']:before
{
    left: -.5em;

   animation: rectangle infinite 1s ease-in-out -.4s;
}
[data-loader='rectangle']:after
{
    right: -.5em;

    animation: rectangle infinite 1s ease-in-out;
}

@include keyframes(rectangle)
{
    0%,
    80%,
    100%
    {
        height: .5em;

        -webkit-box-shadow: 0 0 $color;
                box-shadow: 0 0 $color;
    }
    40%
    {
        height: 1.25em;

        -webkit-box-shadow: 0 -20px $color;
                box-shadow: 0 -20px $color;
    }
}
[data-loader='heart']
{
    position: relative;

    width: 100px;
    height: 90px;

    animation: heart infinite .85s linear;
}
[data-loader='heart']:before,
[data-loader='heart']:after
{
    position: absolute;
    top: 0;
    left: 30px;

    width: 30px;
    height: 50px;

    content: '';
    @include transform(rotate(-45deg));
    @include transform-origin(0 100%);

    -moz-border-radius: 30px 30px 0 0;
         border-radius: 30px 30px 0 0;
    background: $color;
}
[data-loader='heart']:after
{
    left: 0;

    @include transform(rotate(45deg));
    @include transform-origin(100% 100%);
}
@include keyframes(heart)
{
    0%
    {
        @include transform(scale(.8));
    }
    50%
    {
        @include transform(scale(1));
    }
    100%
    {
        @include transform(scale(.8));
    }
}

[data-loader='jumping']
{
    position: relative;

    width: 50px;

    @include perspective(200px);
}
[data-loader='jumping']:before,
[data-loader='jumping']:after
{
    position: absolute;

    width: 20px;
    height: 20px;

    content: '';
    animation: jumping .5s infinite alternate;

    background: rgba(0,0,0,0);
}
[data-loader='jumping']:before
{
    left: 0;
}
[data-loader='jumping']:after
{
    right: 0;

    animation-delay: .15s;
}
@include keyframes(jumping)
{
    0%
    {
        @include transform(scale(1.0) translateY(0px) rotateX(0deg));

        -webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
                box-shadow: 0 0 0 rgba(0,0,0,0);
    }
    100%
    {
        @include transform(scale(1.2) translateY(-25px) rotateX(45deg));

        background: rgb(255,255,255);
        -webkit-box-shadow: 0 25px 40px rgb(255,255,255);
                box-shadow: 0 25px 40px rgb(255,255,255);
    }
}

[data-loader='satellite']
{
    position: relative;

    width: 48px;
    height: 48px;

    animation: satellite 3s infinite linear;

    border: 1px solid $color;
    border-radius: 100%;
}
[data-loader='satellite']:before,
[data-loader='satellite']:after
{
    position: absolute;
    left: 0;

    width: 15px;
    height: 15px;

    content: '';

    border-radius: 100%;
    background-color: $color;
    -webkit-box-shadow: 0 0 10px $color;
            box-shadow: 0 0 10px $color;
}
[data-loader='satellite']:after
{
    right: 0;

    width: 24px;
    height: 24px;
    margin: 12px;
}

@include keyframes(satellite)
{
    from
    {
        @include transform(rotate(0) translateZ(0));
    }
    to
    {
        @include transform(rotate(360deg) translateZ(0));
    }
}

[data-loader='circle-scale']
{
    position: relative;

    width: 36px;
}
[data-loader='circle-scale']:before,
[data-loader='circle-scale']:after
{
    position: absolute;
    left: 0;

    width: 32px;
    height: 32px;

    content: '';
    animation: .75s circle-scale infinite linear alternate;

    border: 3px solid $color;
    border-radius: 100%;
}
[data-loader='circle-scale']:before
{
    margin: 2px;

    @include animation-delay(.35s);
}
[data-loader='circle-scale']:after
{
    width: 36px;
    height: 36px;
}
@include keyframes(circle-scale)
{
    0%
    {
        @include transform(scale(.2));
    }
    100%
    {
        @include transform(scale(1.2));
    }
}

[data-loader='ball-fade']
{
    position: relative;

    width: 15px;
    height: 15px;

    animation: 1.2s ball-fade infinite cubic-bezier(.78, .14, .15, .86) .2s;

    border-radius: 100%;
    background-color: rgba($color, .0);
}
[data-loader='ball-fade']:before,
[data-loader='ball-fade']:after
{
    position: absolute;

    width: 15px;
    height: 15px;

    content: '';
    animation: 1.2s ball-fade infinite cubic-bezier(.78, .14, .15, .86);

    border-radius: 100%;
    background-color: rgba($color, .0);
}
[data-loader='ball-fade']:before
{
    left: -20px;
}
[data-loader='ball-fade']:after
{
    right: -20px;

    @include animation-delay(.4s);
}
@include keyframes(ball-fade)
{
    0%
    {
        background-color: rgba($color, 1);
    }
    100%
    {
        background-color: rgba($color, 0);
    }
}

[data-loader='spinner']
{
    width: 25px;
    height: 25px;

    animation: spinner 1.2s infinite ease-in-out;

    background-color: $color;
}
@include keyframes(spinner)
{
    0%
    {
        @include transform(perspective(120px) rotateX(0) rotateY(0));
    }
    50%
    {
        @include transform(perspective(120px) rotateX(-180deg) rotateY(0));
    }
    100%
    {
        @include transform(perspective(120px) rotateX(-180deg) rotateY(-180deg));
    }
}

[data-loader='ball-roll']
{
    position: relative;
    left: -20px;

    width: 15px;
    height: 15px;

    animation: ball-roll 1s linear infinite alternate;

    border-radius: 50%;
}
@include keyframes(ball-roll)
{
    0%
    {
        background-color: rgba(255,255,255, 1);
        box-shadow: 22px 0 0 0 rgba(255,255,255,.2),
        44px 0 0 0 rgba(255,255,255,.2);
    }
    25%
    {
        background-color: rgba(255,255,255, .4);
        box-shadow: 22px 0 0 0 rgba(255,255,255,2),
        44px 0 0 0 rgba(255,255,255,.2);
    }
    75%
    {
        background-color: rgba(255,255,255, .4);
        box-shadow: 22px 0 0 0 rgba(255,255,255,.2),
        44px 0 0 0 rgba(255,255,255,1);
    }
}

[data-loader='spinner-circle']
{
    width: 25px;
    height: 25px;

    animation: .4s infinite linear spinner-circle;

    border-radius: 50%;
    box-shadow: 30px 0 0 -6px rgba($color, .2),
    0 30px 0 -6px rgba($color, .2),
    -30px 0 0 -6px rgba($color, .2),
    0 -30px 0 -6px rgba($color, .2),
    21px 21px 0 -6px rgba($color, .2),
    -21px 21px 0 -6px rgba($color, .2),
    -21px -21px 0 -6px rgba($color, .2),
    21px -21px 0 -6px rgba($color, .2);
}
@include keyframes(spinner-circle)
{
    12.5%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, 1),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    25%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, 1);
    }
    37.5%
    {
        box-shadow: 30px 0 0 -6px rgba($color, 1),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    50%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, 1),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    62.5%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, 1),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    75%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, 1),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    87.5%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, 1),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, .2),
        21px -21px 0 -6px rgba($color, .2);
    }
    100%
    {
        box-shadow: 30px 0 0 -6px rgba($color, .2),
        0 30px 0 -6px rgba($color, .2),
        -30px 0 0 -6px rgba($color, .2),
        0 -30px 0 -6px rgba($color, .2),
        21px 21px 0 -6px rgba($color, .2),
        -21px 21px 0 -6px rgba($color, .2),
        -21px -21px 0 -6px rgba($color, 1),
        21px -21px 0 -6px rgba($color, .2);
    }
}

[data-loader='circle-clock']
{
    width: 1em;
    height: 1em;

    animation: circle-clock .75s infinite alternate ease-in-out;

    border-radius: 50%;
    background: $color;
    box-shadow: 1.5em 0 0 0 $color;
}
@include keyframes(circle-clock)
{
    0%
    {
        @include transform(rotate(-150deg) scale(1));
    }
    100%
    {
        @include transform(rotate(210deg) scale(.35));

        background: rgba($color, .3);
    }
}

[data-loader='timer']
{
    position: relative;

    width: 24px;
    height: 24px;

    border: 2px solid $color;
    border-radius: 50%;
    background-color: transparent;
}
[data-loader='timer']:after,
[data-loader='timer']:before
{
    position: absolute;

    content: '';

    background-color: $color;
}
[data-loader='timer']:after
{
    top: 11px;
    left: 11px;

    width: 10px;
    height: 2px;

    @include transform-origin(1px 1px);
    animation: timerhand 2s linear infinite;
}

[data-loader='timer']:before
{
    top: 11px;
    left: 11px;

    width: 8px;
    height: 2px;

    @include transform-origin(1px 1px);
    animation: timerhand 8s linear infinite;
}

@include keyframes(timerhand)
{
    0%
    {
        @include transform(rotate(0deg));
    }
    100%
    {
        @include transform(rotate(360deg));
    }
}