body,
html {
	background: $bodyBG;
	color: $bodyFG;
	display: flex;
	flex-direction: column;
	height: 100%;

	@include print {
		background-color: #fff;
		height: auto;
	}
}
.ng-hide {
	opacity: 0;
}
.ng-hide-add, .ng-hide-remove {
	display: block;
	transition: all .5s;
}
.no-animate {
	transition: none;
}
a {
	text-decoration: none;
	cursor: pointer;
	color: color(brand-primary);
	transition: color .25s;

	&:hover {
		color: darken(color(brand-primary), 15%);
	}
}
p {
	margin: .75em 0;
	& + p {
		margin-top: 0;
	}
}
.options {
	text-align: center;
	padding: 2em;

	a {
		display: inline-block;
		line-height: 1;
		padding: 1em 2em;
		margin: 0 .5em;
		border-radius: 3em;
		border: 1px solid color(brand-primary);

		&.selected {
			background-color: color(brand-primary);
			color: #fff;
		}
	}
}
img {
	max-width: 100%;
}
.static-title {
	flex: none;
	margin: 0;
	padding: 1em;
	line-height: 1;
	overflow: initial;

	h1 {
		margin: 0;
		padding: 0;
	}
}
.scroller {
	flex: auto;
	overflow: auto;
	background-color: white;
}
.page {
	height: 100%;
	display: flex;
	flex-direction: column;

	.view {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}
.stats.players, .header.row {
	padding: 0;

	.row.player {
		align-items: stretch;

		&.odd {
			background-color: color(gray-light);
		}
	}

	.player-name {
		flex: 3;
		min-width: 7em;
		padding: .5em;
		display: flex;
    	align-items: center;
	}
	.stat {
		flex: 1;
		align-items: center;
		text-align: center;
		padding: .5em;
		justify-content: center;
		border-left: 1px solid color(gray-medium-light);

		&-count {
			line-height: 1;
			padding: .5em;
			flex: 0;
		}

		&-up, &-down {
			flex: 0;
			line-height: 1;
			cursor: pointer;
			font-size: 1.5em;

			& > * {
				cursor: pointer;
			}
		}
	}
}
.header.row {
	font-weight: bold;
	line-height: 1;
	background-color: color(gray);
	color: white;
	align-items: center;
	line-height: 1;

	.stat {
		white-space: nowrap; 
	    overflow: hidden;
	    text-overflow: ellipsis;
	}

	& > * {
		& > * {
			font-size: .85em;

			&.fas, &.far {
				position: absolute;
				font-size: 1em;
				right: 1em;
				background-color: color(gray);
			}
		}
	}
}