.container {
    max-width: $siteWidth;
    margin: auto;
    padding: 0 2em;
}

.row {
    display: flex;
    flex-direction: row !important;

    & > * {
        flex: 1;
        max-width: 100%;
    }
}
.col {
    display: flex;
    flex-direction: column !important;
}

.flex {
    &-wrap {
        flex-wrap: wrap;

        &-no {
            flex-wrap: nowrap;
        }
        &-reverse {
            flex-wrap: wrap-reverse;
        }
    }
    &-justify {
        &-center {
            justify-content: center;
        }
        &-end {
            justify-content: flex-end;
        }
    }
    &-items {
        &-center {
            align-items: center;
        }
    }
    &-content {
        &-center {
            align-content: center;
        }
    }
    &-self {
        &-center {
            align-self: center;
        }
    }
    &-none {
        flex: none;
    }
    &-auto {
        flex: auto;
    }
    @for $i from 1 through 12 {
        &-#{$i} {
            flex: $i;
        }
    }
}