$size_sm: .5em;
$size_md: 1em; 
$size_lg: 1.5em;
$size_xl: 3em;

@mixin sizes($attr, $side:"") {
	$attrSide:"";
	@if $side != "" {
		$attrSide: -#{$side};
	}

	&#{$attrSide}-none {
		#{$attr}#{$attrSide}: 0 !important;
	}

	&#{$attrSide}-a {
		#{$attr}#{$attrSide}: auto !important;
	}
	&#{$attrSide}_5em {
		#{$attr}#{$attrSide}: .5em !important;
	}

	@for $i from 0 through 20 {
		&#{$attrSide}-#{$i} {
			#{$attr}#{$attrSide}: $i * 1px !important;
		}
		&#{$attrSide}-#{$i}p {
			#{$attr}#{$attrSide}: $i * 1% !important;
		}
		&#{$attrSide}-#{$i}0p {
			#{$attr}#{$attrSide}: $i * 10% !important;
		}
		&#{$attrSide}-#{$i}em {
			#{$attr}#{$attrSide}: $i * 1em !important;
		}
		&#{$attrSide}-#{$i}_25em {
			#{$attr}#{$attrSide}: ($i * 1em) + .25em !important;
		}
		&#{$attrSide}-#{$i}_5em {
			#{$attr}#{$attrSide}: ($i * 1em) + .5em !important;
		}
		&#{$attrSide}-#{$i}_75em {
			#{$attr}#{$attrSide}: ($i * 1em) + .75em !important;
		}
		&#{$attrSide}-#{$i}vh {
			#{$attr}#{$attrSide}: $i * 1vh !important;
		}
		&#{$attrSide}-#{$i}vw {
			#{$attr}#{$attrSide}: $i * 1vw !important;
		}
	}
}

@mixin sides($attr){
	@include sizes(#{$attr});
	@include sizes(#{$attr}, left);
	@include sizes(#{$attr}, top);
	@include sizes(#{$attr}, right);
	@include sizes(#{$attr}, bottom);
}

// Display
.d {
	&-inline {
		display: inline-block !important;
	}
	&-block {
		display: block !important;
	}
	&-none {
		display: none !important;
	}
}

// Margin and padding
.m {
	@include sides(margin);

	&-bottom-gutter {
		margin-bottom: $verticalGutter;
	}
	&-top-gutter {
		margin-top: $verticalGutter;
	}
	&-left-gutter {
		margin-left: $gutter;
	}
	&-right-gutter {
		margin-right: $gutter;
	}
}
.p {
	@include sides(padding);

	&-bottom-gutter {
		& > :last-child {
			margin-bottom: 0;
		}
		padding-bottom: $verticalGutter;
	}
	&-top-gutter {
		padding-top: $verticalGutter;
	}
	&-left-gutter {
		padding-left: $gutter;
	}
	&-right-gutter {
		padding-right: $gutter;
	}
}

.button-rows {
	.btn {
		margin: .25em !important;
	}
}

.pointer {
	cursor: pointer;
}

// Width and height
.w {
	@include sizes(width);
}
.mw {
	@include sizes(max-width);
}
.h {
	@include sides(height);
}
.mh {
	@include sizes(max-height);
}
.j {
	&-center {
		justify-content: center !important;
	}
	&-between {
		justify-content: space-between !important;
	}
}

// Position and float
.pos {
	&-r {
		position: relative !important;
	}
	&-a {
		position: absolute !important;
	}
	&-f {
		position: fixed !important;
	}
}
.f {
	&-right {
		float: right;
	}
	&-left {
		float: left;
	}
	&-none {
		float: none;
	}
}
.flex {
	&-row {
		display: flex;
		flex-direction: row !important;

		& > * {
			flex: 1;
		}
	}
	&-column, &-col {
		display: flex;
		flex-direction: column !important;
	}
	&-none {
		flex: none !important;
	}
	&-auto {
		flex: auto !important;
	}

	&-items {
		&-center {
			align-items: center;
		}
		&-start {
			align-items: flex-start;
		}
		&-end {
			align-items: flex-end;
		}
	}
	&-content {
		&-center {
			align-content: center;
		}
		&-start {
			align-content: flex-start;
		}
		&-end {
			align-content: flex-end;
		}
	}
	&-self {
		&-center {
			align-self: center;
		}
		&-start {
			align-self: flex-start;
		}
		&-end {
			align-self: flex-end;
		}
	}
	&-justify {
		&-center {
			justify-content: center;
		}
		&-start {
			justify-content: flex-start;
		}
		&-end {
			justify-content: flex-end;
		}
		&-between {
			justify-content: space-between;
		}
		&-around {
			justify-content: space-around;
		}
		&-evenly {
			justify-content: space-evenly;
		}
	}

	@for $i from 0 through 10 {
		&-#{$i} {
			flex: $i !important;
		}
		&-#{$i}_25 {
			flex: $i + .25 !important;
		}
		&-#{$i}_5 {
			flex: $i + .5 !important;
		}
		&-#{$i}_75 {
			flex: $i + .75 !important;
		}
	}
}

// Sides
.l {
	@include sizes(left);
}
.t {
	@include sizes(top);
}
.r {
	@include sizes(right);
}
.b {
	@include sizes(bottom);
}

// Opacity
.o {
	@for $i from 0 through 10 {
		&-#{$i} {
			opacity: $i/10;
		}
	}
}
.z {
	@for $i from 1 through 10 {
		&-#{$i}, &-#{$i}-remove {
			z-index: $i !important;
			position: relative;
		}
	}
}
.ul {
	&-i {
		i {
			width: 2em;
			text-align: center;
		}
	}
}
.of {
	&-hidden {
		overflow: hidden !important;
	}
	&-auto {
		overflow: auto !important;
	}
	&-none {
		overflow: inherit !important;
	}
}
.split {
	&:after {
		content: '';
		clear: both;
		display: block;
	}
	.half {
		width: 50%;
		float:left;
		box-sizing: border-box;
		padding-right: 2em;
	}
}
.rotate {
	&-90 {
		transform: rotate(-90deg);
	}
	&-180 {
		transform: rotate(-180deg);
	}
	&-270 {
		transform: rotate(-270deg);
	}
	&-360 {
		transform: rotate(-360deg);
	}
}
.animate {
	transition: all .5s;
	&-delay {
		@for $i from 0 through 10 {
			&-#{$i} {
				transition-delay: $i * 1s;
				&_5 {
					transition-delay: ($i * 1s) + .5s;
				}
			}
		}
	}
}

.flex {
	&-wrap {
		flex-wrap: wrap;

		&-no {
			flex-wrap: nowrap;
		}
		&-reverse {
			flex-wrap: wrap-reverse;
		}
	}
	&-justify {
		&-c {
			justify-content: center;
		}
	}
}

@include query($mobile) {
    .sm {
		&_d {
			&-inline {
				display: inline-block !important;
			}
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
		}

		&_m {
			@include sides(margin);
		}
		&_p {
			@include sides(padding);
		}

		&_w {
			@include sizes(width);
		}
		&_mw {
			@include sizes(max-width);
		}
		&_h {
			@include sides(height);
		}
		&_mh {
			@include sizes(max-height);
		}

    	&_pos {
			&-r {
				position: relative !important;
			}
			&-a {
				position: absolute !important;
			}
			&-f {
				position: fixed !important;
			}
		}
    	&_f {
			&-right {
				float: right;
			}
			&-left {
				float: left;
			}
			&-none {
				float: none;
			}
		}

		&_l {
			@include sizes(left);
		}
		&_t {
			@include sizes(top);
		}
		&_r {
			@include sizes(right);
		}
		&_b {
			@include sizes(bottom);
		}
    }
}