[contenteditable] {
	transition: all .5s;
	background-color: transparent;
	border: none;
	position: relative;
	display: block;
	color: color(gray-medium);
	box-sizing: border-box;
	font-size: 1em;
	font-family: inherit;

	&:empty {
		&:before {
			content: attr(placeholder);
			position: absolute;
			color: color(gray);
			transition: all .5s;
			cursor: text;
		}
	}	

	&:focus {
		box-shadow: 0 2px 0 0 color(blue);
	}
}
select {
    background-image: none;
    background-color: white;
    border: 1px solid color(gray);
    box-shadow: inset 0 -1px 3px rgba(#000, .2);
    font-size: 1em;
	font-family: inherit;
}
input {
	&[type="text"],
	&[type="email"],
	&[type="password"],
	&[type="number"] {
		transition: all .5s;
		background-color: white;
		border: 1px solid color(gray);
		border-radius: $inputRad;
		line-height: 1;
		padding: .25em .5em;
		position: relative;
		display: inline-block;
		color: color(gray-medium);
		box-sizing: border-box;
		font-size: 1em;
		font-family: inherit;
		box-shadow: inset 0 1px 3px rgba(#000, .2);

		&:empty {
			&:before {
				content: attr(placeholder);
				position: absolute;
				color: color(gray);
				transition: all .5s;
				cursor: text;
			}
		}	

		&:focus {
			border-color: color(blue);
			box-shadow: inset 0 1px 2px rgba(#000, .1), 0 0 1px 1px color(blue);
		}
	}

	&[type="checkbox"] {
		$size: 1.3em;
		position: fixed;
		left: -9999px;
		top: -9999px;

		& + label {
			padding-left: $size + .75em;
			position: relative;
			line-height: $size;
    		display: inline-block;

			&:before,
	        &:after {
	            position: absolute;
	            box-sizing: border-box;
	            content: '';
	            transition: all .5s;
	        }

	        &:before {
	            top: 0;
	            left: 0;
	            width: $size;
	            height: $size;
	            border-radius: 3px;
	            border: 1px solid #ccc;
	            background-color: #fff;
	            box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
	        }

	        &:after {
	        	border: 1px solid transparent;
	        	content: unquote("\"#{ $fa-var-check }\"");
                font-family: 'FontAwesome';
                font-weight: 900;
	        	left: 0;
	        	top: 0;
	            z-index: 10;
	            opacity: 0;
	            width: $size;
	            text-align: center;
	            color: color(green);
	            line-height: $size;
	        }
		}
        &:focus + label:before {
            border-color: #999;
        }

        &:checked {
            + label:after {
                opacity: 0.9;
            }

            &:focus + label:after {
                opacity: 1;
            }

            &.partial {
            	+ label:after {
        			opacity: .35;
        		}
        	}
        }
	}

	&[type="radio"] {
		$size: 1.3em;
		position: fixed;
		left: -9999px;
		top: -9999px;

		& + label {
			padding-left: $size + .75em;
			position: relative;
			line-height: $size;
    		display: inline-block;

			&:before,
	        &:after {
	            position: absolute;
	            box-sizing: border-box;
	            content: '';
	            transition: all .5s;
	        }

	        &:before {
	            top: 0;
	            left: 0;
	            width: $size;
	            height: $size;
	            border-radius: 3px;
	            border: 1px solid #ccc;
	            background-color: #fff;
	            box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
	        }

	        &:after {
	        	border: 1px solid transparent;
	        	content: unquote("\"#{ $fa-var-check }\"");
                font-family: 'FontAwesome';
                font-weight: 900;
	        	left: 0;
	        	top: 0;
	            z-index: 10;
	            opacity: 0;
	            width: $size;
	            text-align: center;
	            color: color(green);
	            line-height: $size;
	        }
		}
        &:focus + label:before {
            border-color: #999;
        }

        &:checked {
            + label:after {
                opacity: 0.9;
            }

            &:focus + label:after {
                opacity: 1;
            }
        }
	}
}

.btn, button {
	display: inline-block;
	text-align: center;
	line-height: 1;
	padding: (5em/8) (5em/4);
	position: relative;
	overflow: hidden;
	border: 0;
	border-radius: $rad;
	text-decoration: none;
	font-size: 1em;
	transition: all .5s;
	text-shadow: none;
	text-transform: uppercase;
	font-weight: 600;

	&-caps {
		text-transform: uppercase;
	}

	&-small {
		font-size: .75em;
		padding: (2em/3) (4em/3);
	}
	&-large {
		font-size: 1.25em;
		padding: (3em/5) (6em/5);
	}
	
	&-rounded {
		border-radius: 2em;
	}

	&.ng-hide {
		opacity: 0;
	}

	&.ng-hide-add, &.ng-hide-remove {
		display: block;
	}

	.dxicon {
		display: inline-block;
	    position: relative;
	    font-size: 1.3em;
	    margin: -.5em 0;
	    top: .15em;
	}

	@each $name, $color in $colors {
		&-#{$name} {
			background-color: $color !important;
			@if $name != white {
				color: color(white) !important;
			}
			@else {
				color: color(red) !important;
			}

			&:hover {
				background-color: darken($color, 5%) !important;
			}

			@each $name2, $color2 in $colors {
				&-#{$name2} {
					background-color: $color !important;
					color: $color2 !important;

					&:hover {
						background-color: darken($color, 5%) !important;
					}
				}
			}
		}
		&-outline-#{$name} {
			background-color: #fff;
			box-shadow: inset 0 0 0 1px $color !important;
			color: $color !important;

			&:hover {
				box-shadow: inset 0 0 0 1px darken($color, 10%) !important;
				background-color: lighten($color, 37%) !important;
				color: darken($color, 10%) !important;
			}
		}
	}

	&.disabled {
		opacity: .5;
		background-color: color(gray) !important;
		cursor: not-allowed;
	}
	&-rounded {
		border-radius: 3em;
	}
}