$siteWidth: 1400px !global;
$gutter: 30px;
$verticalGutter: $gutter;
$columns: 12;
$cardWidth: 30em;

$mobile: 640px;
$tablet: 800px;
$desktop: 1200px;

$rad: 6px;
$radBig: 6px;
$inputRad: 3px;

/* Color Variables */

$colors: (
	brand-primary: #4FA3E8,
	brand-secondary: #9B9A49,

	result: #292c40,

	red: #ea1d5d,
	red-dark: #dd1a58,

	orange: #F5A623,
	orange-dark: #ea501f,

	yellow: #fc0,
	yellow-dark: #f1ba2b,

	highlight: #e1f4ab,

	bright-green: #aadd32,
	bright-green-dark: #a1d12f,

	green: #19a830,
	green-dark: #16a02d,

	blue: #1e92fb,
	blue-light: #e0eef9,
	blue-dark: #1c8aee,

	indigo: #5bb6fa,
	indigo-dark: #56adef,

	purple: #ca3faf,
	purple-dark: #bf3ba6,

	gray-dark: #333333,
	gray-medium: #4b4b4b,
	gray-medium-light: #dddddd,
	gray: #969696,
	gray-other: #777777,
	gray-light: #e1e1e1,
	gray-lightest: #f7f7f7,

	day-none: #ccc,
	day-0: #fdcb2e,
	day-1: #f7dadc,
	day-2: #efb4b8,
	day-3: #e68d93,
	day-4: #df6a71,
	day-5: #d7454e,
	day-more: #c90713,

	white: #fff,
	black: #000
);

@function color($name){
	@return map-get($colors, $name);
}

$bodyBG: color(gray-lightest);
$bodyFG: color(black);

@each $name, $color in $colors {
	$pct: 30%;
	.fg-#{$name} {
		color: $color !important;

		&-pale {
			color: lighten($color, $pct) !important;
		}
	}
	.bg-#{$name} {
		background-color: $color !important;

		&-pale {
			background-color: lighten($color, $pct) !important;
		}
	}
	.fill-#{$name} {
		fill: $color !important;
	}
}