.view.game {
	display: flex;
	flex-direction: column;
	height: 100vh;

	.row.scores {
		align-items: center;
		flex: none;

		.vs {
			flex: .25;
			text-align: center;
		}
		.team, .opponent {
			padding: 1em;
			flex: 1;
			text-align: center;
			align-items: center;

			& > * {
				margin: 0;
			}
		}
		.opponent {
			h4 {
				input {
					border: 0;
					border-radius: 0;
					box-shadow: inset 0 -2px color(blue);
					background-color: transparent;
					line-height: 0;
					padding: 0;
					outline: none;
					text-align: center;
				}
				.btn {
					font-size: .65em;
					padding: .5em .75em;
				}
			}
			& > .row {
				justify-content: center;
				.score {
					&-count {
						padding: 0 .4em;
					}
				}
				& > * {
					flex: 0;
				}
			}
		}
	}
}