$textHead: 'Muli';
$textBody: 'Muli';

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800|Chivo:300,400,400i,700');

@mixin textBody {
    font-family: $textBody, sans-serif !important;
    font-weight: normal;
}
@mixin textHead {
    font-family: $textHead, sans-serif !important;
}

html, body {
    font-family: $textBody, sans-serif;
    font-size: 16px;
    line-height: 1.35;
}

p {
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $textHead, sans-serif;
    font-weight: normal;
    margin: .75em 0;
    &:first-child {
        margin-top: 0;   
    }
}

h1,
.h1 {
    font-size: 2.2em;
    line-height: 1.25em;
    @include query($mobile){
        font-size: 2em;
    }
}

h2,
.h2 {
    font-size: 1.9em;
    line-height: 1.25em;
    @include query($mobile){
        font-size: 1.75em;
    }
}

h3,
.h3 {
    font-size: 1.65em;
    line-height: 1.25em;
    @include query($mobile){
        font-size: 1.5em;
    }
}
h4,
.h4 {
    font-size: 1.5em;
    line-height: 1.25em;
    @include query($mobile){
        font-size: 1.25em;
    }
}
h5,
.h5 {
    font-size: 1.25em;
    line-height: 1.25em;
}

i, em, .italics {
    font-style: italic;
}

[font-size] {
    font-size: 1px;
    transition: none !important;
}

.nowrap {
    white-space: nowrap;
}
.wrap {
    white-space: normal;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
}

.uc {
    text-transform: uppercase;
}
.nowrap {
    white-space: nowrap;
}
.fa, .far, .fal, .fas {
    .caption {
        display: none !important;
        font-family: inherit;
    }
}
.ff {
    &-body {
        @include textBody;
    }
    &-head {
        @include textHead;
    }
}
.fs {
    @for $i from 0 through 10 {
        &-#{$i} {
            font-size: $i * 1em !important;
        }
        &-#{$i}vh {
            font-size: $i * 1vh !important;
        }
        &-#{$i}vw {
            font-size: $i * 1vw !important;
        }
        &-#{$i}_25 {
            font-size: ($i * 1em) + .25em !important;
        }
        &-#{$i}_5 {
            font-size: ($i * 1em) + .5em !important;
        }
        &-#{$i}_75 {
            font-size: ($i * 1em) + .75em !important;
        }
    }
}
.a {
    &-center {
        text-align: center !important;
    }
    &-right {
        text-align: right !important;
    }
    &-left {
        text-align: left !important;
    }
    &-full {
        align-self: auto;
    }
}
b, .bold {
    font-weight: bold !important;
}
small {
    font-size: .85em;
}
.td {
    &-strike {
        text-decoration: line-through;
    }
    &-under {
        text-decoration: underline;
    }
    &-over {
        text-decoration: overline;
    }
}
.lh {
    @for $i from 1 through 10 {
        &-#{$i}_5 {
            line-height: ($i * 1) + .5;
        }
        &-#{$i} {
            line-height: $i;
        }
        &-#{$i}em {
            line-height: $i * 1em;
        }
        &-#{$i}_5em {
            line-height: ($i * 1em) + .5em;
        }
        &-#{$i}_5vh {
            line-height: ($i * 1vh) + .5vh;
        }
        &-#{$i}vh {
            line-height: $i * 1vh;
        }
    }
    @for $i from 1 through 48 {
        &-#{$i}px {
            line-height: $i * 1px;
        }
    }
}
.fw {
    &-n {
        font-weight: normal;
    }
    &-b {
        font-weight: bold;
    }
    @for $i from 1 through 9 {
        &-#{$i} {
            font-weight: $i * 100 !important;
        }
    }
}
@include query($mobile){
    .sm {
        &_fs {
            @for $i from 0 through 10 {
                &-#{$i} {
                    font-size: $i * 1em !important;
                }
                &-#{$i}_25 {
                    font-size: ($i * 1em) + .25em !important;
                }
                &-#{$i}_5 {
                    font-size: ($i * 1em) + .5em !important;
                }
                &-#{$i}_75 {
                    font-size: ($i * 1em) + .75em !important;
                }
            }
        }
    }
}