.view.home {
	$searchW: 28em;
	$shadow: 0 2px 8px rgba(#000, .25);

	.overlay {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(#fff, .6);
	}
	.search {
		position: absolute;
		top: 50%;
		left: 50%;
		width: $searchW;
		max-width: calc(100% - 3em);
		transform: translate(-50%, -50%);
		z-index: 3;
		transition-timing-function: ease-in-out;
  		transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		transition: all 1s;
		box-shadow: $shadow;
		border-radius: $inputRad;
		background-color: #fff;
		transition-delay: .5s;

		.instructions {
			color: color(gray-medium);
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			margin-bottom: 1.5em;
			background-color: #fff;
			border-radius: $inputRad;
			box-shadow: $shadow;
			transition: all .25s;
			line-height: 1;

			& > div {
				padding: 1em;
				position: relative;
				background-color: #fff;
				border-radius: $inputRad;
				z-index: 2;

				& > * {
					font-size: .9em;
				}
			}

			&:before, &:after {
				bottom: -.75em;
				left: .9em;
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				border-left: .8em solid transparent;
				border-right: .8em solid transparent;
				border-top: .8em solid white;
				z-index: 3;
			}
			&:after {
				bottom: -.85em;
				border-top: .75em solid rgba(#000, .2);
				filter: blur(3px);
				z-index: 1;
			}

			:first-child {
				margin-top: 0;
			}
			:last-child {
				margin-bottom: 0;
			}
		}

		&-title {
			padding: .5em 1em;
			background-color: #fdcb2e;
			color: #333;
			
			border-top-left-radius: $inputRad;
			border-top-right-radius: $inputRad;

			span {
				font-size: .75em;
				text-transform: uppercase;
			}
		}

		autocomplete {
			display: block;
			position: relative;
			flex: 1;
			margin: auto;
			z-index: 3;
			height: 3em;

			.autocomplete {
				display: block;
				margin: auto;
				transition: all .25s, height 0s, padding 0s;
				height: 3em;

				&.has-list {
					&:before {
						content: '';
						display: block;
						width: 100%;
						height: 3px;
						position: absolute;
						bottom: 0;
						background-color: #fff;
						left: 0;
						border-bottom: 1px solid color(gray-light);
						z-index: 4;
					}
				}

				&-list {
					border: 0;
					border-top-right-radius: 0;
					border-top-left-radius: 0;
					width: 100%;
					text-align: left;
					box-shadow: $shadow;
					z-index: 3;
					max-height: 19.35em;

					li {
						padding: .75em 1em;
					}
				}
			}
		}

		input {
			width: 100%;
			padding: 1em;
			height: 3em;
			box-sizing: border-box;
			text-align: left;
			border: 0;
			border-radius: 0;
			box-shadow: none;
			background-color: transparent;
			transition: all .5s, padding 0s, height 0s;

			&:focus {
				box-shadow: none;
			}
		}

		&.changed {
			transform: translate(0,0);
			top: 1.5em;
			left: 1.5em;
		}

		&.loading {
			transition-delay: 0s;
			transition: opacity .5s;

			.instructions {
				transition: none;
			}

			autocomplete {
				.autocomplete {
					&-close {
						transition: none;
					}
				}
			}
		}
	}
	.legend {
		transition: none;

		ul {
			transition: all .35s;
			height: 15em;
			overflow: hidden;
			border-top: 1px solid color(gray-light);

			&.ng-hide {
				height: 0;
			}
		}

		&.ng-hide {
			ul {
				height: 0;
			}
		}

		.legend-toggle {
			display: block;
			text-align: center;
			padding: .25em;
			background-color: color(gray-lightest);
			border-bottom-left-radius: $inputRad;
			border-bottom-right-radius: $inputRad;
			position: absolute;
			top: 100%;
			width: 4em;
			left: 50%;
			border-top: 1px solid color(gray-light);
			transform: translateX(-50%);
			box-shadow: $shadow;
			color: color(gray);
			transition: all .25s;

			&:hover {
				border-color: color(gray-medium-light);
				background-color: color(gray-medium-light);
				color: color(gray-medium);
			}
		}

		li {
			display: flex;
			flex-direction: row;
			position: relative;
			transition: all .25s;
			cursor: pointer;
			align-items: center;

			.legend-close {
				position: absolute;
				right: 1em;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover {
				background-color: color(gray-lightest);
			}

			span {
				flex: auto;
				padding-left: 1em;
			}

			&.unselected {
				span {
					opacity: .5;
				}
			}
			&.selected {
				background-color: #f2efe9;
				span {
					font-weight: bold;
				}
			}

			&:before, &:after {
				content: '';
				flex: none;
				width: 2.5em;
				height: 2.5em;
				z-index: 2;
				position: relative;
			}
			&:after {
				background-color: #f2efe9;
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
			}
			&:last-child {
				&:before, &:after {
					border-bottom-left-radius: $inputRad;
				}
			}

			&.day-0 {
				&:before {
					background-color: rgba(#fdcb2e, .6);
				}
			}
			&.day-1 {
				&:before {
					background-color: rgba(#c90713, .1);
				}
			}
			&.day-2 {
				&:before {
					background-color: rgba(#c90713, .2);
				}
			}
			&.day-3 {
				&:before {
					background-color: rgba(#c90713, .3);
				}
			}
			&.day-4 {
				&:before {
					background-color: rgba(#c90713, .4);
				}
			}
			&.day-5 {
				&:before {
					background-color: rgba(#c90713, .5);
				}
			}
			&.day-more {
				&:before {
					background-color: rgba(#c90713, .6);
				}
			}
		}
	}
	.map {
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		box-sizing: border-box;
		transition: all .5s;
		z-index: 1;

		.angular-leaflet-map {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}